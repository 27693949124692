@use 'sass:math';
//@import 'palette';
@import 'sweetalert2/src/variables';

// Theme variables
//$mui-button-border-radius: 5px;
//$wo-button-border-radius: 26px;
//$material-ui-disabled: #808080;
//$material-ui-white: #fff;
//$material-ui-slider-track-height: 2px;
//$material-ui-slider-thumb-size: 16px;

// Variables to be added to Sweetalert2
$swal2-actions-width: 100%;
$swal2-actions-margin: 13px auto 0;
$swal2-border-radius: 8px;
$swal2-button-focus-background: none;
$swal2-close-button-font-family: arial;
$swal2-container-padding: 0.625em 30px;
//$swal2-font: $font-family;
$swal2-font-size: 14px;
$swal2-html-container-font-size: 1em;
$swal2-html-container-color: #140229;
$swal2-html-container-line-height: 1.44;
$swal2-html-container-margin: 1rem 20px 0.3em;
//$swal2-padding: 0 0 25px 0;
$swal2-title-color: #140229;
$swal2-title-font-size: 18px;
$swal2-title-font-weight: 800;
//$swal2-title-text-align: left;
$swal2-title-text-align: center;
$swal2-title-padding: 28px 20px 0;
$swal2-width: 400px;

// override SASS variables here
// CONFIRM BUTTON
$swal2-confirm-button-border: 1px solid rgba(33, 33, 33, 0.5);
//$swal2-confirm-button-border-radius: $wo-button-border-radius;
//$swal2-confirm-button-background-color: $brand-color;
//$swal2-confirm-button-color: $brand-text;
$swal2-confirm-button-focus-box-shadow: none;
$swal2-confirm-button-font-size: 16px;

// DENY BUTTON
$swal2-deny-button-border: 0;
//$swal2-deny-button-border-radius: $wo-button-border-radius;
$swal2-deny-button-background-color: rgba(43, 21, 21, 0);
//$swal2-deny-button-color: $primary-main;
$swal2-deny-button-focus-box-shadow: none;
$swal2-deny-button-font-size: 16px;

// CANCEL BUTTON
$swal2-cancel-button-border: 1px solid rgba(96, 84, 110, 0.3);
//$swal2-cancel-button-border-radius: $wo-button-border-radius;
$swal2-cancel-button-background-color: rgba(255, 255, 255, 0);
//$swal2-cancel-button-color: #140229;
$swal2-cancel-button-focus-box-shadow: none;
$swal2-cancel-button-font-size: 16px;

// BUTTONS
//$swal2-button-darken-hover: rgba($primary-main, 0.1);
//$swal2-button-darken-active: rgba($primary-main, 0.3);
$swal2-button-focus-box-shadow: none;
$swal2-button-padding: 15px 18px 14px 18px;
$swal2-button-font-weight: bold;

// ACTIONS
$swal2-actions-justify-content: center;
$swal2-actions-padding: 0 20px;

// CLOSE BUTTON
$swal2-close-button-border-radius: 50%;
$swal2-close-button-hover-background: rgb(242, 242, 242);
//$swal2-close-button-hover-color: $swal2-close-button-color;

// CHECKBOX
$checkbox-size-px: 18;
$checkbox-left-px: -2;

// ICONS
//$swal2-success: $success-main;
$swal2-icon-size: 3em;
$swal2-icon-font-size: 1.75em;

@import 'sweetalert2/src/sweetalert2';
//
//// Temporary / Permanent workarounds
.swal2-container {
  z-index: 1560;
}

.swal2-popup {
  border-radius: 14px;
}
//
//// Input validation error
//.swal2-input {
//  &.swal2-inputerror {
//    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20height%3D%2232%22%20style%3D%22overflow%3Avisible%3Benable-background%3Anew%200%200%2032%2032%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2232%22%20xml%3Aspace%3D%22preserve%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cg%3E%3Cg%20id%3D%22Error_1_%22%3E%3Cg%20id%3D%22Error%22%3E%3Ccircle%20cx%3D%2216%22%20cy%3D%2216%22%20id%3D%22BG%22%20r%3D%2216%22%20style%3D%22fill%3A%23D72828%3B%22%2F%3E%3Cpath%20d%3D%22M14.5%2C25h3v-3h-3V25z%20M14.5%2C6v13h3V6H14.5z%22%20id%3D%22Exclamatory_x5F_Sign%22%20style%3D%22fill%3A%23E6E6E6%3B%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
//    background-repeat: no-repeat;
//    background-position: right 3% top 50%;
//    background-size: 1em auto, 100%;
//  }
//}
//
//// Disabled (actions) buttons
.swal2-styled {
  //  //flex-grow: 1;
  //  //min-width: 33.333333%;
  //  //min-width: 127px;
  //  min-width: calc(50% - 10px);
  //  max-width: 100%;
  //  letter-spacing: -0.16px;
  //  line-height: 1.2;
  //
  //  &.max-50 {
  //    width: calc(50% - 10px);
  //    max-width: calc(50% - 10px);
  //  }
  //  &.max-100 {
  //    width: 100%;
  //    max-width: 100%;
  //  }
  //
  //  &[disabled] {
  //    opacity: 1;
  //    //color: $material-ui-disabled;
  //  }
  //
  //  &:focus {
  //    background-color: $swal2-button-focus-background;
  //  }
  //
  //  &.swal2-confirm {
  //    //background-color: $brand-color;
  //  }
  &.swal2-cancel {
    //color: $swal2-cancel-button-color;
    color: inherit;
    font-weight: normal;
  }
}
//
//// Close button additional style
//.swal2-close {
//  font-family: $swal2-close-button-font-family;
//}
//
//// Select element arrow
//.swal2-select {
//  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
//  background-repeat: no-repeat, repeat;
//  background-position: right 0.7em top 50%, 0 0;
//  background-size: 0.65em auto, 100%;
//  appearance: none;
//}
//
//// Radio button
//.swal2-radio {
//  input {
//    &[type='radio'] {
//      opacity: 0; // hiding the radio input
//    }
//  }
//
//  label {
//    position: relative; // Create a new containing block
//  }
//
//  .swal2-label {
//    padding-left: 0.2em;
//
//    &::before {
//      content: '';
//      position: absolute;
//      top: 0;
//      left: 0;
//      width: 18px;
//      height: 18px;
//      border: 2px solid $material-ui-disabled;
//      border-radius: 100%;
//    }
//  }
//}
//
//[type='radio'] {
//  &:hover + .swal2-label {
//    &::before {
//      //border: 2px solid $primary-main;
//    }
//  }
//
//  &:checked + .swal2-label {
//    &::before {
//      //border: 2px solid $primary-main;
//    }
//
//    &::after {
//      content: '';
//      position: absolute;
//      top: 5px;
//      left: 5px;
//      width: 12px;
//      height: 12px;
//      border-radius: 100%;
//      //background: $primary-main;
//    }
//  }
//}
//
//// Checkbox
//.swal2-checkbox {
//  input {
//    position: relative;
//    left: 30px;
//    width: 40px;
//    height: 40px;
//    transition: opacity 0.3s, transform 0.2s;
//    border-radius: 50%;
//    outline: none;
//    opacity: 0;
//    //background-color: $primary-main;
//    box-shadow: none;
//    appearance: none;
//    pointer-events: none;
//
//    &:checked,
//    &:indeterminate {
//      //background-color: $primary-main;
//    }
//
//    &:checked + span {
//      &::before {
//        //border-color: $primary-main;
//        //background-color: $primary-main;
//      }
//
//      &::after {
//        border-color: $material-ui-white;
//      }
//    }
//
//    &:indeterminate + span {
//      &::before {
//        //border-color: $primary-main;
//        //background-color: $primary-main;
//      }
//
//      &::after {
//        transform: translate($checkbox-left-px * 2px, math.div($checkbox-size-px, 6) * 1px);
//        border-left: 0;
//        //border-color: $primary-main;
//      }
//    }
//
//    &:active {
//      transform: scale(0);
//      transition: transform 0s, opacity 0s;
//      opacity: 1;
//
//      + span {
//        &::before {
//          //border-color: $primary-main;
//        }
//      }
//    }
//
//    &:checked {
//      &:active + span {
//        &::before {
//          border-color: transparent;
//          //background-color: $primary-main;
//        }
//      }
//    }
//
//    &:focus {
//      opacity: 0.12;
//    }
//  }
//
//  span {
//    &::before {
//      content: '';
//      position: relative;
//      top: 1px;
//      left: $checkbox-left-px * 1px;
//      box-sizing: border-box;
//      width: $checkbox-size-px * 1px;
//      height: $checkbox-size-px * 1px;
//      float: left;
//      transition: border-color 0.2s, background-color 0.2s;
//      border: solid 2px;
//      border-radius: 2px;
//      border-color: $material-ui-disabled;
//      vertical-align: top;
//    }
//
//    &::after {
//      content: '';
//      position: relative;
//      left: (-$checkbox-size-px + $checkbox-left-px) * 1px;
//      width: 10px;
//      height: -$checkbox-left-px * 2px;
//      float: left;
//      transform: translate(math.div($checkbox-size-px, 6) * 1px, -$checkbox-left-px * 2px) rotate(-45deg); /* stylelint-disable-line */
//      border: solid 2px transparent;
//      border-top: 0;
//      border-right: 0;
//    }
//  }
//
//  &:hover {
//    > input {
//      opacity: 0.04;
//
//      &:focus {
//        opacity: 0.16;
//      }
//    }
//  }
//}
//
//// Mixin for range / slider styling
//@mixin slider-track {
//  height: $material-ui-slider-track-height;
//  //background: lighten($primary-main, 30%);
//}
//
//@mixin slider-thumb {
//  width: $material-ui-slider-thumb-size;
//  height: $material-ui-slider-thumb-size;
//  margin-top: -1 * math.div($material-ui-slider-thumb-size, 2) + 1; /* stylelint-disable-line */
//  border: 0;
//  border-radius: 50%;
//  //background: $primary-main;
//  appearance: none;
//}
//
//[type='range'] {
//  appearance: none;
//
//  &:focus {
//    outline: none;
//  }
//
//  // Chrome does not support browser's pseudo elements comma-separated
//  // We need to write all styles for each browser engine
//  // sass-lint:disable no-vendor-prefixes
//  &::-webkit-slider-runnable-track {
//    @include slider-track;
//  }
//
//  &::-moz-range-track {
//    @include slider-track;
//  }
//
//  &::-ms-track {
//    @include slider-track;
//  }
//
//  &::-webkit-slider-thumb {
//    @include slider-thumb;
//  }
//
//  &::-moz-range-thumb {
//    @include slider-thumb;
//  }
//
//  &::-ms-thumb {
//    @include slider-thumb;
//  }
//
//  // There's no equivalent pseudo element for webkit
//  &::-moz-range-progress {
//    //background: $primary-main;
//  }
//}
//
//.animate__animated.animate__fastest {
//  -webkit-animation-duration: 0.225s;
//  animation-duration: 0.225s;
//}
