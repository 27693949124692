// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

// Sweet Alert2
//@import 'sweetalert2/src/sweetalert2';
@import 'kmwine-sweetalert2';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }

  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;

    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }

  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

// ==============================|| CUSTOM ||============================== //

// 샵정보 [START]
#business-hours-table {
  margin-top: 20px;

  thead {
    tr {
      td {
        h5 {
          text-align: center;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 5px 10px;

        &.biz-hour {
          padding: 5px 2px 5px 10px;
        }

        &.biz-min {
          padding: 5px 10px 5px 2px;
        }
      }
    }
  }
}

// 샵정보 [END]

// calculate table style
.calculate-table {
  border-collapse: collapse;

  th,
  td {
    border-bottom: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
    padding: 12px;
  }
  th:first-child,
  td:first-child {
    border-left: none;
  }
}

// order-table
#order-table {
  tbody {
    tr {
      td {
        padding: 12px;
      }
    }
  }
}

// 정산관리 [START]
#calculator-sum-table {
  width: 100%;
  border: 1px solid #e5e5e5;

  table {
    thead {
      border-bottom: 1px solid #eeeeee;

      tr {
        th {
          background-color: #eeeeee;
          border-right: 1px solid #e5e5e5;
          padding: 2px 6px;
        }
        th:last-child {
          border-right: none;
        }
      }
    }

    tbody {
      tr {
        td {
          border-right: 1px solid #eeeeee;
          padding: 2px 6px;
        }
        td:last-child {
          border-right: none;
        }
      }
    }
  }
}
#calculator-table-paper {
  width: 100%;
  overflow-y: hidden;
  border-radius: 0;
  border: none;

  .calculator-table-container {
    max-height: 440px;
    border: 1px solid #e5e5e5;

    table {
      thead {
        border-bottom: 1px solid black;

        tr {
          th {
            background-color: #eeeeee;
            border-right: 1px solid #e5e5e5;
            padding: 2px 6px;

            //&.change-color {
            //  background-color: rgba(33, 150, 243, 0.08);
            //}
          }
          th:last-child {
            border-right: none;
          }
        }
      }

      tbody {
        tr {
          td {
            border-right: 1px solid #eeeeee;
            padding: 2px 6px;
          }
          td:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}
// 정산관리 [END]

// 말줄임표 1줄
.line-1-ellipsis {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
